import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, of, switchMap, tap } from 'rxjs';

import { CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { tapAllResponses } from '@celum/core';
import { LibraryVariant } from '@celum/libraries/domain';
import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';
import { FilterState } from '@celum/shared/domain';
import { SearchAndSelectComponent } from '@celum/shared/ui';

import { variantTypes } from '../variant-types.const';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    OverlayModule,
    TranslateModule,

    CelumIconModule,
    CelumPipesModule,
    CelumEmptyPageModule,
    CelumLoadingMaskModule,
    CelumDirectivesModule,
    TranslationModule,
    SearchAndSelectComponent
  ],
  selector: 'libs-variants-filter',
  templateUrl: './variants-filter.component.html',
  styleUrls: ['./variants-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: VariantsFilterComponent,
      multi: true
    }
  ]
})
export class VariantsFilterComponent extends ComponentStore<FilterState<LibraryVariant>> implements ControlValueAccessor {
  @HostBinding('class.libs-variants-filter') public hostCls = true;

  @Output() public readonly valueChanged = new EventEmitter<LibraryVariant[]>();

  public vm$ = this.select(state => state);

  protected searchValue$ = this.select(state => state.searchValue);

  protected menuOpened = this.effect<boolean>(menuOpen$ =>
    menuOpen$.pipe(
      tap(value => this.patchState({ loading: true, isOpen: value })),
      switchMap(isMenuOpen => {
        if (isMenuOpen === null) {
          return of([]);
        }
        if (isMenuOpen === false) {
          return of(null);
        }

        this.patchState({ searchValue: '' });

        return combineLatest([of(variantTypes), this.searchValue$.pipe(debounceTime(300))], (variants: LibraryVariant[], searchValue: string) => {
          return searchValue
            ? variants.filter(variant => variant.name[this.translateService.currentLang].toLowerCase().includes(searchValue.toLowerCase()))
            : variants;
        });
      }),
      tapAllResponses(
        (filteredValues: LibraryVariant[]) => {
          this.patchState({ loading: false });

          if (filteredValues) {
            this.patchState({ filteredValues });
          }
        },
        error => console.error('AssetsFilterComponent: An error occurred while fetching AssetFilters: ', error)
      )
    )
  );
  protected readonly addNewVariant = IconConfiguration.large('add-variants')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);

  constructor(private translateService: TranslateService) {
    super({
      selectedValues: [],
      searchValue: '',
      loading: false,
      filteredValues: [],
      isOpen: false,
      onTouched: () => null,
      onChange: () => null
    });
  }

  public itemSelectionChanged(metadata: LibraryVariant[]): void {
    this.patchState({ selectedValues: metadata });
    this.get().onChange?.(metadata);
  }

  public writeValue(obj: LibraryVariant[]): void {
    this.patchState({ selectedValues: obj });
  }

  public registerOnChange(fn: any): void {
    this.patchState({ onChange: fn });
  }

  public registerOnTouched(fn: any): void {
    this.patchState({ onTouched: fn });
  }
}

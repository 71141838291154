import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

import { CelumEmptyPageModule, CelumLoadingMaskModule, EmptyPageConfig } from '@celum/common-components';
import { CelumListModule } from '@celum/internal-components';
import { Asset } from '@celum/libraries/domain';
import { CelumDirectivesModule, HasOverflowDirective } from '@celum/ng2base';

import { LibraryAssetCardComponent } from '../asset-card/asset-card.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,

    CelumDirectivesModule,
    CelumListModule,
    CelumEmptyPageModule,
    CelumLoadingMaskModule,
    HasOverflowDirective,

    LibraryAssetCardComponent
  ],
  selector: 'libs-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LibraryAssetsListComponent {
  @HostBinding('class.libs-assets-list') public hostCls = true;
  @Input() public assets: Asset[] = [];
  @Input() public emptyPageConfig: EmptyPageConfig;
  @Input() public loading: boolean;

  // When the last, invisible item gets scrolled in the frame, we inform the parent so that it can load the next batch
  @Output() public readonly lastItemIsVisible = new EventEmitter<boolean>();
}

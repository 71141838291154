import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';

import { UserDetails, UserService } from '@celum/authentication';
import { CelumIconModule } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { TranslationModule } from '@celum/ng2base';
import { CelumChipComponent, CelumChipSetComponent } from '@celum/shared/ui';

import { LibraryFilter } from '../../dashboard.component';

export interface DashboardInfoHeaderState {
  appliedFilters: LibraryFilter[];
}

export interface DashboardInfoHeaderViewModel {
  appliedFilters: LibraryFilter[];
  userName: string;
  userOid: string;
}

@Component({
  selector: 'libs-dashboard-info-header',
  templateUrl: './dashboard-info-header.component.html',
  styleUrls: ['./dashboard-info-header.component.scss'],
  imports: [TranslateModule, CommonModule, MatTooltipModule, CelumIconModule, TranslationModule, CelumChipComponent, CelumChipSetComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardInfoHeaderComponent extends ComponentStore<DashboardInfoHeaderState> {
  @Input() public numberOfFilteredItems: number;

  @Input() public totalNumberOfItems: number;

  @Input() public isFilterApplied: boolean;

  @Output() public readonly filterChanged = new EventEmitter<LibraryFilter[]>();

  protected vm$ = combineLatest([this.state$, this.userService.currentUser$.pipe(isTruthy())]).pipe(
    map(([state, currentUser]) => this.createViewModel(state, currentUser))
  );

  constructor(private userService: UserService) {
    super({ appliedFilters: [] });
  }

  @Input() public set appliedFilters(value: LibraryFilter[]) {
    this.patchState({ appliedFilters: value });
  }

  protected trackFn(index: number): number {
    return index;
  }

  protected removeFilter(filterToRemove: LibraryFilter): void {
    this.filterChanged.emit(this.get().appliedFilters.filter(filter => filter.key !== filterToRemove.key));
  }

  protected applyCreatedByFilter(userName: string, userOid: string): void {
    const filter: LibraryFilter = {
      key: 'createdBy',
      value: userOid,
      displayValue: userName,
      titleKey: 'LIBRARIES.DASHBOARD.FILTERS.CREATED_BY'
    };
    this.filterChanged.emit([filter]);
  }

  private createViewModel(state: DashboardInfoHeaderState, currentUser: UserDetails): DashboardInfoHeaderViewModel {
    return {
      appliedFilters: state.appliedFilters,
      userName: `${currentUser.firstName} ${currentUser.lastName}`,
      userOid: currentUser.oid
    };
  }
}

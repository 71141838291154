<mat-basic-chip class="celum-chip celum-chip--{{ color }}" [class.celum-chip--error]="errorTooltip" disableRipple [disabled]="disabled">
  <celum-icon *ngIf="prefixIconConfig" class="celum-chip_prefix-icon" [configuration]="prefixIconConfig" [matTooltip]="errorTooltip | translate"></celum-icon>
  <span *ngIf="prefix" class="celum-chip_prefix">{{ prefix | translate }}</span>
  <span class="celum-chip_text">{{ text | translate }}</span>
  <celum-icon
    *ngIf="!disabled"
    class="celum-chip_action-icon"
    [configuration]="closeIconConfig"
    [matTooltip]="'COMMON.REMOVE' | translate"
    (click)="remove.emit(true)"></celum-icon>
</mat-basic-chip>

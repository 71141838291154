import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule, IconConfiguration } from '@celum/common-components';

@Component({
             selector: 'celum-wizard-actions',
             standalone: true,
             imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, TranslateModule, CelumIconModule],
             templateUrl: './wizard-actions.component.html',
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumWizardActionsComponent {
  @HostBinding('class') public readonly hostClass = 'celum-wizard_actions';

  @Input() public loading = false;
  @Input() public isCreate = true;

  @Input() public nextButtonDisabled = false;
  @Input() public nextButtonVisible = true;

  @Input() public backButtonDisabled = false;
  @Input() public backButtonVisible = true;

  @Input() public submitButtonDisabled = false;
  @Input() public submitButtonVisible = false;

  @Output() public readonly submitClicked = new EventEmitter<void>();
  @Output() public readonly backClicked = new EventEmitter<void>();
  @Output() public readonly nextClicked = new EventEmitter<void>();

  protected icons = {
    next: IconConfiguration.small('right-arrow').withColor('inherit'),
    back: IconConfiguration.small('left-arrow').withColor('inherit'),
    save: IconConfiguration.small('check-m').withColor('inherit')
  };
}

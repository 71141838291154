<ng-container *ngIf="vm$ | async as vm">
  <div
    #libraryListRow
    class="library-list-row"
    (contextmenu)="$event.preventDefault()"
    (click)="vm.canOpenLibraryPreview && previewLibrary.emit(vm.library)"
    data-test-library-list-row-container
    [ngClass]="{ editable: vm.isLibraryOwner && vm.isLibraryOwnerActive, active: vm.library.status === LibraryStatus.ACTIVE }">
    <div
      class="library-list-row_icon"
      [ngClass]="vm.library.status === LibraryStatus.ACTIVE ? 'library-list-row_icon--active' : 'library-list-row_icon--inactive'"
      [matTooltip]="(vm.library.status === LibraryStatus.ACTIVE ? 'LIBRARIES.LIST.ROW.ACTIVE_LIBRARY' : 'LIBRARIES.LIST.ROW.INACTIVE_LIBRARY') | translate">
      <celum-icon *ngIf="vm.library.status === LibraryStatus.ACTIVE" [clickable]="false" [configuration]="icons.activeLibraryIcon"></celum-icon>
      <celum-icon *ngIf="vm.library.status === LibraryStatus.INACTIVE" [clickable]="false" [configuration]="icons.inactiveLibraryIcon"></celum-icon>
    </div>
    <div class="library-list-row_name" spaceAwareTooltip [matTooltip]="vm.library.name">{{ vm.library.name }}</div>
    <div class="library-list-row_avatar">
      <celum-user-avatar
        [accountAccessToken]="(userService.accountAccessForCurrentTenant$ | async).accountAccessToken"
        [user]="vm.creator | async"
        [tooltipText]="!vm.isLibraryOwnerActive ? ('LIBRARIES.LIST.ROW.DEACTIVATED_USER_TOOLTIP' | translate) : undefined"></celum-user-avatar>
    </div>
    <div class="library-list-row_filter">
      <celum-icon [clickable]="false" [configuration]="icons.filterIcon"></celum-icon>
      <span spaceAwareTooltip [matTooltip]="vm.library.syncDefinition.syncSource.downSyncFilter.name" [matTooltipShowDelay]="500">
        {{ vm.library.syncDefinition.syncSource.downSyncFilter.name }}
      </span>
      <celum-icon
        *ngIf="vm.library.error"
        [attr.data-test-library-list-row-error]="vm.library.error.key.toUpperCase()"
        [clickable]="false"
        [configuration]="icons.errorIcon"
        [matTooltip]="'LIBRARIES.ERROR.' + vm.library.error.key.toUpperCase() | translate"></celum-icon>
    </div>
  </div>
  <span
    *ngIf="vm.isLibraryOwner"
    class="library-list-row_menu-trigger-element"
    style="position: fixed"
    #menuTriggerElement
    [contextMenuHandler]="libraryListRow"
    [matMenuTriggerFor]="libraryMenu"></span>
  <mat-menu #libraryMenu xPosition="after" [hasBackdrop]="false">
    <button
      *ngIf="vm.library.status === LibraryStatus.ACTIVE"
      class="library-list-row_menu-item"
      data-test-library-list-row-context-menu-entry-edit
      mat-menu-item
      (click)="editLibrary.emit(vm.library)">
      <celum-icon [configuration]="icons.editIcon"></celum-icon>
      <span>{{ 'LIBRARIES.LIST.ROW.EDIT_LIBRARY' | translate }}</span>
    </button>
    <button
      *ngIf="vm.library.status === LibraryStatus.ACTIVE"
      class="library-list-row_menu-item"
      data-test-library-list-row-context-menu-entry-deactivate
      mat-menu-item
      (click)="changeStatusOfLibrary.emit(LibraryStatus.INACTIVE)">
      <celum-icon [configuration]="icons.inactiveIconSmall"></celum-icon>
      <span>{{ 'LIBRARIES.LIST.ROW.DEACTIVATE' | translate }}</span>
    </button>
    <ng-container *ngIf="vm.library.status === LibraryStatus.INACTIVE">
      <button
        class="library-list-row_menu-item"
        data-test-library-list-row-context-menu-entry-activate
        mat-menu-item
        (click)="changeStatusOfLibrary.emit(LibraryStatus.ACTIVE)">
        <celum-icon [configuration]="icons.activateIcon"></celum-icon>
        <span>{{ 'LIBRARIES.LIST.ROW.ACTIVATE' | translate }}</span>
      </button>
      <button class="library-list-row_menu-item" data-test-library-list-row-context-menu-entry-delete mat-menu-item (click)="deleteLibrary.emit(vm.library)">
        <celum-icon [configuration]="icons.deleteIcon"></celum-icon>
        <span>{{ 'LIBRARIES.LIST.ROW.DELETE' | translate }}</span>
      </button>
    </ng-container>
    <button
      *ngIf="vm.library.status === LibraryStatus.ACTIVE && !vm.isProd"
      class="library-list-row_menu-item"
      mat-menu-item
      (click)="openJSON.emit(vm.library)">
      <celum-icon [configuration]="icons.codeIcon"></celum-icon>
      <span>Open JSON</span>
    </button>
  </mat-menu>
</ng-container>

<button
    mat-button
    class="celum-wizard_actions_stepper-button"
    data-test-wizard-back-button
    [disabled]="backButtonDisabled"
    [style.visibility]="backButtonVisible ? 'visible' : 'hidden'"
    (click)="backClicked.emit()">
  <div class="celum-wizard_action-button-content">
    <celum-icon [configuration]="icons.back"></celum-icon>
    {{ 'COMMON.BACK' | translate | uppercase }}
  </div>
</button>

<button
    mat-button
    class="celum-wizard_actions_stepper-button"
    data-test-wizard-next-button
    [disabled]="nextButtonDisabled"
    [style.visibility]="nextButtonVisible ? 'visible' : 'hidden'"
    (click)="nextClicked.emit()">
  <div class="celum-wizard_action-button-content">
    {{ 'COMMON.NEXT' | translate | uppercase }}
    <celum-icon [configuration]="icons.next"></celum-icon>
  </div>
</button>

<button
    class="celum-wizard_actions_submit-button"
    mat-button
    color="primary"
    data-test-wizard-submit-button
    [disabled]="submitButtonDisabled"
    (click)="submitClicked.emit()">
  <div class="celum-wizard_action-button-content">
    <mat-spinner *ngIf="loading" [diameter]="14"></mat-spinner>
    <celum-icon *ngIf="!loading" [configuration]="icons.save"></celum-icon>

    {{ (isCreate ? 'COMMON.CREATE' : 'COMMON.SAVE') | translate | uppercase }}
  </div>
</button>

import { schema } from 'normalizr';

import { ExperiencePermissions } from '@celum/authentication';
import { Entity, EntityRegistry, EntityTranslator } from '@celum/ng2base';

export const ACCOUNT_USER = `AccountUser`;

export enum InvitationStatus {
  /** For email based invitation. The user has to accept the invitation to become a member. */
  INVITED = 'INVITED',
  /** The user accepted a personal (email based) invitation. */
  ACCEPTED = 'ACCEPTED',
  /** The user rejected a personal (email based) invitation. */
  REJECTED = 'REJECTED',
  /** For shared link based invitation requests. One of the account managers has to approve the invitation for the user to become a member. */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /** An account manager approved the link based invitation request. */
  APPROVED = 'APPROVED',
  /** An account manager rejected the link based invitation request. */
  DISAPPROVED = 'DISAPPROVED'
}

export enum UserState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}

export interface AccountUser extends Entity {
  firstName: string;
  lastName: string;
  email: string;
  profilePictureDownloadLink: string;
  status: UserState;
  invitationStatus: InvitationStatus;
  experiencePermissions: ExperiencePermissions[];
}

export const accountUserTranslator: EntityTranslator<AccountUser> = (json: any) => ({
  id: json.oid,
  typeKey: ACCOUNT_USER,
  firstName: json.firstName,
  lastName: json.lastName,
  email: json.email,
  status: json.status,
  invitationStatus: json.invitationStatus,
  profilePictureDownloadLink: json.profilePictureDownloadLink,
  experiencePermissions: json.experiencePermissions
});

const accountUserSchema = new schema.Entity<AccountUser>(ACCOUNT_USER);

EntityRegistry.register<AccountUser>({
  typeKey: ACCOUNT_USER,
  translator: accountUserTranslator,
  schema: accountUserSchema
});
